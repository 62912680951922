@media screen and (max-width:1580px) { 
  .card-view .row:not(.row-head){
    width: calc(25% - 15px);
  }
}

@media screen and (max-width:1280px) { 
  .card-view .row:not(.row-head){
    width: calc(33.33% - 15px);
  }
}

@media screen and (max-width:1180px) { 
  h2{
    width:100%;
    margin-bottom:20px;
  }
  .list-header h2{
    width:auto;
    max-width:40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom:0;
  }
}

@media screen and (max-width:1080px) { 
  .admin-sidebar{
    padding-left:0;
  }
  aside{
    left:-250px;
    background-color: #f3f5f8;
  }
  .admin-mobile-menu{
    display:inline-block;
    vertical-align:middle;
  }
  .sidebar-open{
    overflow-x: hidden;
  }
  .sidebar-open aside{
    left:0;
  }
  .sidebar-open .admin-content{
    left:250px;
  }
}

@media screen and (max-width: 880px) {
  .signup-wrapper{
    padding-left:30px;
    padding-right:30px;
  }
  .privacy-policy{
    padding-left:30px;
    padding-right:30px;
  }
}

@media screen and (max-width: 800px) {
  .iconic-button{
    padding:10px;
    width:28px;
  }

  .admin-title img{
    height:38px;
  }
  .signup-step .signup-step-wrapper-left{
      padding-right:30px;
  }
  .signup-step .signup-step-wrapper-right{
      padding-left: 30px;
  }
  .signup-step .signup-step-wrapper ul li{
    width: calc(50% - 15px);
  }
  .signup-step .signup-step-wrapper-left ul li,
  .signup-step .signup-step-wrapper-right ul li{
    width:100%;
  }

  .admin-content-body{
    padding:20px;
  }
  .admin-element{
    padding:20px;
  }

  .list-header{
    flex-wrap:wrap;
    margin-bottom: 10px;
  }
  .list-header h2,
  .list-action{
    justify-content:flex-start;
    width:100%;
  }
  .list-header h2{
    margin-bottom:20px;
  }
  .list-views{
    display:none;
  }
  .list-search{
    width:100%;
  }
  .list-search input[type="text"]{
    width:100%;
  }
  .listing-view .row{
    padding: 0 20px;
    margin: 0 -20px;
  }
  .listing-view .cols .rounded-thumb{
    width: 58px;
    height: 58px;
    border-radius: 58px;
  }
  .listing-view .cols img{
    max-width:100%;
  }
  .listing-view .cols img.rounded{
    border-radius: 58px;
  }

  .details-header h2{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom:0;
    width:calc(100% - 180px);
  }
  .details-header .details-action{
    width:180px;
  }
  .details-view > .row,
  .details-view .tab-content > .row{
    flex-wrap:wrap;
  }
  .details-view > .row > .cols,
  .details-view .tab-content > .row > .cols{
    width:100%;
    margin-bottom:20px;
  }
  .details-view > .row > .cols:last-child,
  .details-view .tab-content > .row > .cols:last-child{
    margin-bottom:0;
  }

  .card-view{
    justify-content:space-between;
  }
  .card-view .row:not(.row-head){
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin: 0 0 20px 0;
  }
}

@media screen and (max-width:767px) { 
  .menu {
    margin-left:-5px;
    margin-right:5px;
  }
  .signup-step .signup-step-wrapper{
    padding:0;
  }
  .signup-step .signup-step-wrapper-left,
  .signup-step .signup-step-wrapper-right{
      width:100%;
      padding-left:0;
      padding-right:0;
      border-right:0;
  }
  .signup-step .signup-step-wrapper-right .facebook,
  .signup-step .signup-step-wrapper-right .google{
      margin:0;
  }
  .signup-step .signup-step-wrapper p,
  .signup-step .signup-step-wrapper-left ul li,
  .signup-step .signup-step-wrapper-right ul li,
  .signup-step .signup-step-wrapper ul li{
      margin-bottom:15px;
  }
  .signup-step .signup-step-wrapper ul li{
    width:100%;
  }
  .signup-step .btn{
      width:100%;
  }
  .signup-step .signup-step-wrapper .success-icon img{
    width:98px;
  }
  .admin-content-wrapper-inner{
    padding-left:0;
  }
  .admin-content-header{
    padding:10px 15px;
  }
  .admin-content-body{
    padding:15px;
  }
  .admin-element{
    padding:15px;
  }
  .privacy-policy{
    padding-left: 15px;
    padding-right: 15px;
  }
  .privacy-policy img{
    padding:0 15px;
  }
}

@media screen and (max-width: 613px) {
  .cols-w-100-mobile{
    width:100% !important;
    margin:0 0 20px;
  }

  .signup-wrapper ul li{
    width:100%;
  }

  .list-action, .details-action{
    flex-wrap:wrap;
  }
  .list-action{
    justify-content: space-between;
  }
  .list-views,
  .list-search,
  .list-filter,
  .list-add-remove{
    width:calc(50% - 10px);
    margin:0 0 10px 0;
  }
  .list-search{
    width:100%;
  }
  .list-filter .button,
  .list-search input[type="text"]{
    width:100%;
    margin:0;
  }

  .listing-view{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .listing-view .row.row-head{
    display:none;
  }
  .listing-view .row:not(.row-head){
    display: inline-block;
    padding: 20px;
    width: 100%;
    max-width: 100%;
    margin: 0 0 15px 0;
    background: #ffffff;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    transition:transform .2s,box-shadow .2s;
  }
  .listing-view .row:not(.row-head):last-child{
    margin:0;
  }
  .listing-view .row:not(.row-head) .cols{
    width:100%;
    padding: 0 0 7px 0;
  }
  .listing-view .row:not(.row-head) .cols.cols-align-center{
    text-align:left;
  }
  .listing-view .cols .rounded-thumb{
    margin-bottom: 15px;
  }
  .listing-view .row:not(.row-head) .cols label.card-label{
    text-align: left;
    display: inline-block;
    width: 80px;
  }

  .details-view .row, .tab-content .row{
    flex-wrap:wrap;
    margin-bottom:0;
  }
  .details-view input[type="text"], .details-view input[type="email"], .details-view input[type="password"], .details-view select{
    width:100%;
    max-width:100%;
  }

  .card-view .row:not(.row-head){
    width:100%;
    max-width:100%;
    margin:0 0 15px 0;
  }
}

@media screen and (max-width:568px) { 
  header .logo{
    max-width:100%;
    height:auto;
  }
}