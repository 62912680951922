@import url(https://fonts.googleapis.com/css?family=Fira+Sans:100,200,300,400,500,600,700&subset=cyrillic);
.Toastify__toast-container {
  z-index: 9999;
  transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: auto;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: 48px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 6px 16px;
  border-radius: 1px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    line-height: 1.43;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
  text-align: left;
  color: rgb(45, 44, 44);
  background-color: #fff;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--dark {
  background: #121212;
  color: rgb(45, 44, 44);;
}
.Toastify__toast--default {
  background-color: rgb(50, 50, 50);
  color: rgb(45, 44, 44);;
}
.Toastify__toast--info {
  color: rgb(45, 44, 44);;
  border-top: #f15825 3px solid;
}
.Toastify__toast--success {
  color: rgb(45, 44, 44);;
  border-top: #35cc62 3px solid;
}
.Toastify__toast--warning {
  color: rgb(45, 44, 44);;
  border-top: #e3871f 3px solid;
}
.Toastify__toast--error {
  color: rgb(45, 44, 44);;
  border-top: #ed2b4f 3px solid;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}
.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--default {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@-webkit-keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  -webkit-animation: Toastify__trackProgress linear 1 forwards;
          animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: auto;
  left: initial;
  transform-origin: right;
}
.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
.Toastify__progress-bar--dark {
  background: #bb86fc;
}
@-webkit-keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@-webkit-keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  -webkit-animation-name: Toastify__bounceInLeft;
          animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  -webkit-animation-name: Toastify__bounceInRight;
          animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  -webkit-animation-name: Toastify__bounceInDown;
          animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  -webkit-animation-name: Toastify__bounceInUp;
          animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  -webkit-animation-name: Toastify__bounceOutLeft;
          animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  -webkit-animation-name: Toastify__bounceOutRight;
          animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  -webkit-animation-name: Toastify__bounceOutUp;
          animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  -webkit-animation-name: Toastify__bounceOutDown;
          animation-name: Toastify__bounceOutDown;
}

@-webkit-keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  -webkit-animation-name: Toastify__zoomIn;
          animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  -webkit-animation-name: Toastify__zoomOut;
          animation-name: Toastify__zoomOut;
}

@-webkit-keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@-webkit-keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  -webkit-animation-name: Toastify__flipIn;
          animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  -webkit-animation-name: Toastify__flipOut;
          animation-name: Toastify__flipOut;
}

@-webkit-keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@-webkit-keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  -webkit-animation-name: Toastify__slideInLeft;
          animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  -webkit-animation-name: Toastify__slideInRight;
          animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  -webkit-animation-name: Toastify__slideInDown;
          animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  -webkit-animation-name: Toastify__slideInUp;
          animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  -webkit-animation-name: Toastify__slideOutLeft;
          animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  -webkit-animation-name: Toastify__slideOutRight;
          animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  -webkit-animation-name: Toastify__slideOutUp;
          animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  -webkit-animation-name: Toastify__slideOutDown;
          animation-name: Toastify__slideOutDown;
}
  @font-face {
  font-family:jet-icons;
  src:url(/static/media/jet-icons.c91006c5.eot);
  src:url(/static/media/jet-icons.c91006c5.eot#iefix) format("embedded-opentype"),
  url(/static/media/jet-icons.e0874a17.ttf) format("truetype"),
  url(/static/media/jet-icons.dd94af68.woff) format("woff"),
  url(/static/media/jet-icons.5e252e22.svg#jet-icons) format("svg");
  font-weight:400;
  font-style:normal
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, var,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, ul, li {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
	list-style:none;
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
	display:block;
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	outline:none;
  position: relative;
}

.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.clearfix {
	display: block;
}

html, body {
	width:100%;
	height:100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Fira Sans",sans-serif !important;
  font-size: 14px;
	color: #768191;
	padding:0;
	margin:0;
	-moz-text-size-adjust: none ;
	 -ms-text-size-adjust: none ;
	     text-size-adjust: none ;
	-webkit-text-size-adjust:none;
  background-color: #f3f5f8;
}

img {
	display:block;
	max-width:100%;
	margin:0 auto;
}

a, .link{
  display:inline-block;
	color:#2e3a49;
	text-decoration:none;
	transition:all .5s ease;
	-webkit-transition:all .5s ease;
  -moz-transition:all .5s ease;
  cursor:pointer;
}

a:hover{
	color:#2e3a49;
}


[class*=' icon-'],
[class^=icon-] {
 font-family:jet-icons!important;
 font-style:normal;
 font-weight:400;
 font-feature-settings:normal;
 font-variant:normal;
 text-transform:none;
 line-height:1;
 vertical-align:middle;
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale
}
.icon-admins:before {
 content:""
}
.icon-after-date-including:before {
 content:""
}
.icon-after-date:before {
 content:""
}
.icon-alarm:before {
 content:""
}
.icon-alert:before {
 content:""
}
.icon-antenna:before {
 content:""
}
.icon-apple:before {
 content:""
}
.icon-arrow-backward-2:before {
 content:""
}
.icon-arrow-backward-3:before {
 content:""
}
.icon-arrow-backward:before {
 content:""
}
.icon-arrow-down-2:before {
 content:""
}
.icon-arrow-down-3:before {
 content:""
}
.icon-arrow-down:before {
 content:""
}
.icon-arrow-forward-2:before {
 content:""
}
.icon-arrow-forward-3:before {
 content:""
}
.icon-arrow-forward:before {
 content:""
}
.icon-arrow-up-2:before {
 content:""
}
.icon-arrow-up-3:before {
 content:""
}
.icon-arrow-up:before {
 content:""
}
.icon-attach-clip:before {
 content:""
}
.icon-bank:before {
 content:""
}
.icon-bar-code:before {
 content:""
}
.icon-battery-half-power:before {
 content:""
}
.icon-battery-low:before {
 content:""
}
.icon-before-date-including:before {
 content:""
}
.icon-before-date:before {
 content:""
}
.icon-bills:before {
 content:""
}
.icon-bin:before {
 content:""
}
.icon-blocks:before {
 content:""
}
.icon-book:before {
 content:""
}
.icon-bookmakers:before {
 content:""
}
.icon-briefcase:before {
 content:""
}
.icon-browser:before {
 content:""
}
.icon-brush:before {
 content:""
}
.icon-calendar:before {
 content:""
}
.icon-camera:before {
 content:""
}
.icon-chart:before {
 content:""
}
.icon-chat:before {
 content:""
}
.icon-check_2:before {
 content:""
}
.icon-check_3:before {
 content:""
}
.icon-check_4:before {
 content:""
}
.icon-check:before {
 content:""
}
.icon-chemistry:before {
 content:""
}
.icon-cherry:before {
 content:""
}
.icon-chip:before {
 content:""
}
.icon-circle:before {
 content:""
}
.icon-close:before {
 content:""
}
.icon-cloud-download:before {
 content:""
}
.icon-cloud-upload:before {
 content:""
}
.icon-cloud:before {
 content:""
}
.icon-comments:before {
 content:""
}
.icon-components:before {
 content:""
}
.icon-configure:before {
 content:""
}
.icon-console:before {
 content:""
}
.icon-contains:before {
 content:""
}
.icon-contrast:before {
 content:""
}
.icon-copy:before {
 content:""
}
.icon-countries:before {
 content:""
}
.icon-croissant:before {
 content:""
}
.icon-cup_of_coffee:before {
 content:""
}
.icon-currency:before {
 content:""
}
.icon-cursor:before {
 content:""
}
.icon-cut:before {
 content:""
}
.icon-dash:before {
 content:""
}
.icon-dashboard:before {
 content:""
}
.icon-delete:before {
 content:""
}
.icon-deselect:before {
 content:""
}
.icon-diagram:before {
 content:""
}
.icon-diamond:before {
 content:""
}
.icon-dishes:before {
 content:""
}
.icon-document:before {
 content:""
}
.icon-documents:before {
 content:""
}
.icon-dot:before {
 content:""
}
.icon-dots_small:before {
 content:""
}
.icon-dots:before {
 content:""
}
.icon-download:before {
 content:""
}
.icon-duplicate_2:before {
 content:""
}
.icon-duplicate:before {
 content:""
}
.icon-earth_planet:before {
 content:""
}
.icon-edit:before {
 content:""
}
.icon-email:before {
 content:""
}
.icon-ends_with:before {
 content:""
}
.icon-enlarge_expand:before {
 content:""
}
.icon-equals:before {
 content:""
}
.icon-eye:before {
 content:""
}
.icon-facebook:before {
 content:""
}
.icon-feather:before {
 content:""
}
.icon-fileds:before {
 content:""
}
.icon-filter-down:before {
 content:""
}
.icon-filter-up:before {
 content:""
}
.icon-filter:before {
 content:""
}
.icon-folder:before {
 content:""
}
.icon-football-sports:before {
 content:""
}
.icon-gamepad:before {
 content:""
}
.icon-gear:before {
 content:""
}
.icon-gift:before {
 content:""
}
.icon-glasses:before {
 content:""
}
.icon-greater-than-or-equals:before {
 content:""
}
.icon-greater-than:before {
 content:""
}
.icon-hand:before {
 content:""
}
.icon-health:before {
 content:""
}
.icon-heart:before {
 content:""
}
.icon-help-filled:before {
 content:""
}
.icon-help:before {
 content:""
}
.icon-home:before {
 content:""
}
.icon-human-being:before {
 content:""
}
.icon-idea:before {
 content:""
}
.icon-image:before {
 content:""
}
.icon-incoming-call:before {
 content:""
}
.icon-is-null:before {
 content:""
}
.icon-key:before {
 content:""
}
.icon-layers-2:before {
 content:""
}
.icon-layers:before {
 content:""
}
.icon-leagues:before {
 content:""
}
.icon-less-than-or-equals:before {
 content:""
}
.icon-less-than:before {
 content:""
}
.icon-letter-template:before {
 content:""
}
.icon-link:before {
 content:""
}
.icon-linkedin:before {
 content:""
}
.icon-location:before {
 content:""
}
.icon-lock-open:before {
 content:""
}
.icon-log-out:before {
 content:""
}
.icon-longtext:before {
 content:""
}
.icon-magic-wand:before {
 content:""
}
.icon-magnet:before {
 content:""
}
.icon-matches:before {
 content:""
}
.icon-menu:before {
 content:""
}
.icon-minus:before {
 content:""
}
.icon-model-link:before {
 content:""
}
.icon-more:before {
 content:""
}
.icon-move:before {
 content:""
}
.icon-multiselect:before {
 content:""
}
.icon-new-document:before {
 content:""
}
.icon-news:before {
 content:""
}
.icon-notification:before {
 content:""
}
.icon-number:before {
 content:""
}
.icon-one-of:before {
 content:""
}
.icon-open-folder:before {
 content:""
}
.icon-open-site-countries:before {
 content:""
}
.icon-pages:before {
 content:""
}
.icon-payments:before {
 content:""
}
.icon-pen:before {
 content:""
}
.icon-percent:before {
 content:""
}
.icon-phone:before {
 content:""
}
.icon-pin:before {
 content:""
}
.icon-pizza:before {
 content:""
}
.icon-play-2:before {
 content:""
}
.icon-play:before {
 content:""
}
.icon-plus:before {
 content:""
}
.icon-pocket:before {
 content:""
}
.icon-posts:before {
 content:""
}
.icon-power-measure:before {
 content:""
}
.icon-power:before {
 content:""
}
.icon-proposed-restaurants:before {
 content:""
}
.icon-qr-code:before {
 content:""
}
.icon-ranks:before {
 content:""
}
.icon-redo:before {
 content:""
}
.icon-reflect:before {
 content:""
}
.icon-repeat:before {
 content:""
}
.icon-rocket:before {
 content:""
}
.icon-save:before {
 content:""
}
.icon-search:before {
 content:""
}
.icon-section:before {
 content:""
}
.icon-select-all:before {
 content:""
}
.icon-select:before {
 content:""
}
.icon-sending:before {
 content:""
}
.icon-server:before {
 content:""
}
.icon-settings:before {
 content:""
}
.icon-shield:before {
 content:""
}
.icon-shopping-bag:before {
 content:""
}
.icon-shopping-cart-fool:before {
 content:""
}
.icon-shopping-cart:before {
 content:""
}
.icon-starts-with:before {
 content:""
}
.icon-switch:before {
 content:""
}
.icon-table-dashboard:before {
 content:""
}
.icon-table:before {
 content:""
}
.icon-tag:before {
 content:""
}
.icon-target:before {
 content:""
}
.icon-taxi:before {
 content:""
}
.icon-teams:before {
 content:""
}
.icon-text:before {
 content:""
}
.icon-time:before {
 content:""
}
.icon-tips:before {
 content:""
}
.icon-toggle-theme:before {
 content:""
}
.icon-transactions:before {
 content:""
}
.icon-twitter:before {
 content:""
}
.icon-uncheck:before {
 content:""
}
.icon-undo:before {
 content:""
}
.icon-upload:before {
 content:""
}
.icon-user:before {
 content:""
}
.icon-users-teams:before {
 content:""
}
.icon-versions:before {
 content:""
}
.icon-warning-filled:before {
 content:""
}
.icon-warning:before {
 content:""
}
.icon-whistle:before {
 content:""
}
.icon-windows:before {
 content:""
}
.icon-lock-close:before {
 content:""
}
.icon-admin:before {
 content:""
}
.icon-data:before {
 content:""
}
.icon-workflow:before {
 content:""
}
.icon-plus-circle:before {
 content:""
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select{
  font-family: "Fira Sans",sans-serif;
  font-size: 14px;
  color: #768191;
  padding: 10px 15px;
  border: 1px solid #eceff5;
  border-radius: 6px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus, #country-select:focus{
  border-color: #c3c6cc;
}

textarea{
  line-height:22px;
  min-height:150px;
  white-space:inherit;
  overflow:inherit;
  text-overflow:inherit;
}

.input-icon {
  position: absolute;
  display: inline-block;
  color: #bac3d0;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  pointer-events: none;
  z-index:1;
  margin:1px 3px;
}

.input-icon + input{
  padding-left: 32px;
}
select, #country-select{
  font-family: "Fira Sans",sans-serif;
  font-size: 14px;
  color: #768191;
  padding: 9px 30px 9px 15px;
  border: 1px solid #eceff5 !important;
  border-radius: 6px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background:#ffffff url(/static/media/down-arrow.7fcc81b2.svg) calc(100% - 10px) center no-repeat;
  background-size:10px;
}

#country-select::-webkit-input-placeholder{
  font-family: "Fira Sans",sans-serif;
  font-size: 14px;
  color: #768191 !important;
  opacity: 1;
}

#country-select:-ms-input-placeholder{
  font-family: "Fira Sans",sans-serif;
  font-size: 14px;
  color: #768191 !important;
  opacity: 1;
}

#country-select::-ms-input-placeholder{
  font-family: "Fira Sans",sans-serif;
  font-size: 14px;
  color: #768191 !important;
  opacity: 1;
}

#country-select::placeholder{
  font-family: "Fira Sans",sans-serif;
  font-size: 14px;
  color: #768191 !important;
  opacity: 1;
}

.checkbox {
  font-family: "Fira Sans",sans-serif;
	font-size: 13px;
	line-height: 20px;
  display: block;
  position: relative;
  height: 18px;
  width: 18px;
  margin:0 !important;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  z-index:1;
}

.checkbox .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border: 1px solid #bababa;
  border-radius:4px;
}

.checkbox:hover input ~ .checkmark {
  background-color: #ffffff;
}

.checkbox input:checked ~ .checkmark {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
  background: url(/static/media/tick-black.3a2185c8.svg) center no-repeat;
  background-size: 100% auto;
}

.button,
button{
  font-family: "Fira Sans",sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #141414;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  padding: 10px 20px;
  margin: 0 5px;
  border: 0;
  border-radius: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  background: #f2f4fb;
}

.button.icon,
button.icon{
  display:flex;
  justify-content:center;
  align-items:center;
}

.button.icon [class^=icon-],
button.icon [class^=icon-]{
  font-size: 16px;
  color: #141414;
  margin-right:5px;
  transition:all .5s ease;
  -webkit-transition:all .5s ease;
  -moz-transition:all .5s ease;
}

.button.icon:hover [class^=icon-],
button.icon:hover [class^=icon-]{
  color:#ffffff;
}

.iconic-button{
  font-family: "Fira Sans",sans-serif;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  /* padding: 0 20px; */
  padding:10px 20px;
  margin: 0 5px;
  border: 0;
  border-radius: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  width:32px;
  /* height:32px; */
  background: #f2f4fb;
}

.iconic-button span{
  font-size: 16px;
  color: #141414;
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100%;
}

.button:hover,
button:hover,
.iconic-button:hover{
  color: #ffffff;
  background: #141414;
  box-shadow: 0 8px 26px 0 rgba(0, 0, 0, .2);
}

.iconic-button:hover span{
  color: #ffffff;
}

input[type="submit"]:disabled,
button:disabled,
.btn:disabled{
  cursor:default;
  color:#768191;
  font-weight:400;
  background:rgba(128, 128, 128, .3);
  border-color:rgba(128, 128, 128, .1);
  box-shadow:none;
}

.button-primary, 
.button-primary:hover, 
.button-primary:visited {
  text-transform:uppercase;
  color: #fff;
  background: #f15825;
}

.button-file{
  position:relative;
  width:auto !important;
  margin:0 !important;
  opacity:1 !important;
}

.button-file span.button-icon{
  font-size:16px;
}

.button-file input[type="file"]{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  opacity:0;
}

.dropdown {
  position:absolute;
  padding:12px;
  line-height:normal;
  text-align:left;
  opacity:0;
  pointer-events:none;
  z-index:2;
  transform:scale(0);
  transform-origin:right top;
  transition:transform .2s,opacity .2s
}

.dropdown-anchor:hover>.dropdown,
.dropdown-inline-anchor:hover>.dropdown {
  opacity:1;
  transform:scale(1);
  pointer-events:auto
}

.dropdown-position-bottom-left {
  top:100%;
  right:0;
  margin-top:-4px;
  margin-right:-8px;
  transform-origin:right top
}

.dropdown-position-top-right-middle {
  bottom:50%;
  left:50%;
  margin-bottom:-12px;
  margin-left:-12px;
  transform-origin:left bottom
}

.dropdown-inner {
  min-width:200px;
  background:#ffffff;
  box-shadow:0 10px 60px 0 rgba(0,0,0,.15);
  border-radius:0 12px 12px;
  -webkit-overflow-scrolling:touch
}

.dropdown-position-bottom-left .dropdown-inner {
  border-radius:12px 0 12px 12px
}

.dropdown-position-bottom-right .dropdown-inner {
  border-radius:0 12px 12px
}

.dropdown-inner .dropdown-position-bottom {
  border-radius:0 0 12px 12px
}

.dropdown-fully-radius .dropdown-inner {
  border-radius:12px;
  overflow:hidden
}

.dropdown-dark .dropdown-inner {
  background-color:#353e48
}

.dropdown-item {
  position:relative;
  display:block;
  font-size:12px;
  padding:10px 15px;
  cursor:pointer
}

.dropdown-item,
.dropdown-item:hover,
.dropdown-item:visited {
  text-decoration:none
}

.dropdown-item:hover:nth-child(2n),
.dropdown-item:nth-child(2n),
.dropdown-item:visited:nth-child(2n) {
  color:#768191;
  background:#f8fafc
}

.dropdown-item:hover:nth-child(2n+1),
.dropdown-item:nth-child(2n+1),
.dropdown-item:visited:nth-child(2n+1) {
  color:#768191;
  background:#ffffff;
}

.dropdown-item:hover:nth-child(2n) {
  color:#ffffff;
  background:#607eff
}

.dropdown-item:hover:nth-child(2n+1) {
  color:#ffffff;
  background:#5572ec
}

.dropdown-menu .dropdown-item {
  height:40px;
  padding:14px 26px;
  font-size:11px;
  font-weight:500
}

.dropdown-menu .dropdown-item:hover:nth-child(2n),
 .dropdown-menu .dropdown-item:nth-child(2n),
 .dropdown-menu .dropdown-item:visited:nth-child(2n) {
  color:#768191;
  background:0 0
}

.dropdown-menu .dropdown-item:hover:nth-child(2n+1),
.dropdown-menu .dropdown-item:nth-child(2n+1),
.dropdown-menu .dropdown-item:visited:nth-child(2n+1) {
  color:#768191;
  background:0 0
}

.dropdown-menu .dropdown-item:hover:nth-child(2n) {
  color:#141414;
  background:0 0
}

.dropdown-menu .dropdown-item:hover:nth-child(2n+1) {
  color:#141414;
  background:0 0
}

.dropdown-position-bottom-left .dropdown-item:first-child {
  border-radius:12px 0 0
}

.dropdown-position-bottom-right .dropdown-item:first-child {
  border-radius:0 12px 0 0
}

.dropdown-position-bottom-left .dropdown-item:last-child,
.dropdown-position-bottom-right .dropdown-item:last-child {
  border-radius:0 0 12px 12px
}

.dropdown-position-bottom-left .dropdown-item:first-child:last-child {
  border-radius:12px 0 12px 12px
}

.dropdown-position-bottom-right .dropdown-item:first-child:last-child {
  border-radius:0 12px 12px
}

.dropdown-item-selected:hover:nth-child(2n),
.dropdown-item-selected:nth-child(2n) {
  color:#2e3a49
}

.dropdown-item-selected:hover:nth-child(2n+1),
.dropdown-item-selected:nth-child(2n+1) {
  color:#2e3a49
}

.dropdown-item-disabled {
  opacity:.5;
  cursor:not-allowed
}

.dropdown-item-hidden {
  display:none
}

.dropdown-item-icon {
  float:left;
  display:inline-block;
  color:#768191;
  width:16px;
  font-size:14px;
  margin-right:10px;
  vertical-align:middle;
  text-align:center
}

.dropdown-menu .dropdown-item-icon {
  display:inline-block;
  width:22px;
  font-size:18px;
  opacity:.7
}

.dropdown-menu .dropdown-item:hover .dropdown-item-icon,
.dropdown-menu .dropdown-item-selected .dropdown-item-icon {
  color:#141414
}

.dropdown-item-label {
  display:block
}

.dropdown .dropdown-item-label {
  margin-left:0
}

.dropdown-icons>.dropdown-inner>.dropdown-item>.dropdown-item-label {
  margin-left:26px
}

.dropdown-with-toggle>.dropdown-inner>.dropdown-item>.dropdown-item-label {
  padding-right:30px
}

.dropdown-menu>.dropdown-inner>.dropdown-item>.dropdown-item-label {
  margin-left:12px
}

.dropdown-item-status {
  float:right
}

.dropdown-section {
  padding:10px 0;
  border-bottom:1px solid #eceff5
 }

.dropdown-section:last-child {
  border-bottom:0
}

.dropdown-section-title {
  color:#b6bfcc;
  font-size:10px;
  font-weight:500;
  text-transform:uppercase;
  padding:8px 26px
}

.dropdown-dark .dropdown-section-title {
  border-color:#768191
}

.error input[type="text"],
.error input[type="email"],
.error input[type="password"],
.error textarea,
.error select{
  border-color:#f12525;
  border-bottom-left-radius:0; 	-moz-border-radius-bottomleft:0; 	-webkit-border-bottom-left-radius:0;
  border-bottom-right-radius:0; 	-moz-border-radius-bottomright:0; 	-webkit-border-bottom-right-radius:0;
}

.error .error-msg{
  font-size:13px;
  color:#ffffff;
  padding:10px;
  background:#f12525;
  border-bottom-left-radius:6px; 	-moz-border-radius-bottomleft:6px; 	-webkit-border-bottom-left-radius:6px;
  border-bottom-right-radius:6px; 	-moz-border-radius-bottomright:6px; 	-webkit-border-bottom-right-radius:6px;
}

.error.error-info-block{
  margin-bottom:10px;
}

.error.error-info-block .error-msg{
  border-radius:6px;
}

h1, h2, h3, h4, h5, h6{
  font-family: "Fira Sans",sans-serif;
  font-weight: 400;
}

h2{
  font-size:22px;
  color: #768191;
}

h3{
  font-size:18px;
  color: #768191;
}

p{
  line-height:22px;
}

hr{
  height:0px;
  border:0px;
  border-bottom:1px solid rgba(0,0,0,.10);
}

.container{
	width:100%;
	max-width:1140px;
  margin:0 auto;
}

/* Header */

header{
  text-align:center;
  padding:50px;
  position:fixed;
  width:100%;
}

header .logo{
  height:92px;
  width:auto;
  max-width:inherit;
  margin:0 auto;
}

header.dark{
  text-align:left;
  padding:15px;
  background:#3d3d3d;
}

header.dark .logo{
  height:28px;
  margin:0;
}

/* Login - Signup */

.login-signup-wrapper{
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100vh;
  position:relative;
}

.login-signup-wrapper:after{
  content:"";
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,.6);
}

.login-signup-box{
  width:300px;
  margin-top:100px;
  background:#ffffff;
  box-shadow: 0 0 40px 4px #111118;
  position:relative;
  z-index:1;
}

.login-signup-box-back{
  color: #333;
  position: absolute;
  top: 14px;
  left: 14px;
  height: 10px;
  width: 10px;
  background: #ffffff;
  box-sizing: content-box;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  border: 6px solid #ffffff;
  cursor: pointer;
  line-height: 0;
  opacity:0;
  visibility:hidden;
}

.login-signup-box-back.visible{
  opacity:1;
  visibility:visible;
}

.login-signup-box-close{
  color: #333;
  position: absolute;
  top: 14px;
  right: 14px;
  height: 10px;
  width: 10px;
  background: #ffffff;
  box-sizing: content-box;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  border: 6px solid #ffffff;
  cursor: pointer;
  line-height: 0;
  z-index: 1;
}

.login-signup-box-title{
  font-size:26px;
  display:flex;
  justify-content:center;
  align-items:center;
  height:80px;
  background:rgba(241,241,241,0.8);
}

.login-signup-tabs ul{
  display:flex;
}

.login-signup-tabs ul li{
  text-align:center;
  width:50%;
  border-bottom:1px solid rgba(92,102,111,0.2);
}

.login-signup-tabs ul li.active{
  border-bottom:1px solid #5c666f;
}

.login-signup-tabs ul li span{
  display: block;
  color:rgba(92,102,111,0.6);
  padding:15px;
  cursor:pointer;
}

.login-signup-tabs ul li.active span{
  color:#5c666f;
}

.login-signup-tab-content{
  display:inline-block;
  display:none;
  width:100%;
  overflow:hidden;
}

.login-signup-tab-content.active-tab-content{
  display:block;
}

.login-signup-tab-content-wrapper-inside{
  padding:20px;
  min-height:170px;
}

.login-signup-tab-content-wrapper-inside p{
  margin-bottom:15px;
}

.login-signup-tab-content-wrapper-inside p:last-child{
  margin-bottom:0;
}

.login-signup-tab-content-wrapper-inside p a{
  color:#f15825;
}

.login-signup-tab-content ul li{
  text-align:center;
  display:inline-block;
  width:100%;
  margin-bottom:10px;
}

.login-signup-tab-content ul li:last-child{
  margin-bottom:0;
}

#login.login-signup-tab-content ul li:last-child{
  margin-top:10px;
}

#login.login-signup-tab-content ul li:first-child{
  margin-top:0;
}

#login.login-signup-tab-content .login-signup-tab-content-wrapper{
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  width:600px;
  transition:all .5s ease;
}

#login.login-signup-tab-content .login-signup-tab-content-wrapper.forgot-password{
  margin-left:-300px;
}

#login.login-signup-tab-content .login-box,
#login.login-signup-tab-content .forgot-password-box{
  width:300px;
}

.login-signup-tab-content ul li input[type="text"]{
  color: rgba(0,0,0,0.8);
  width:100%;
}

.login-signup-tab-content button, .login-signup-tab-content a.signup-link{
  font-size:16px;
  color: #ffffff;
  text-transform: uppercase;
  width:calc(100% - 40px);
  padding:15px 30px;
  margin:0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f15825;
  background: #f15825;
}

.login-signup-tab-content .button svg,
.login-signup-tab-content button svg{
  margin-top:2px;
  margin-left:5px;
}

.login-signup-tab-content .button:hover,
.login-signup-tab-content button:hover{
  color: #ffffff;
  background: #141414;
  border-color:#141414;
}

.login-signup-tab-content .button:disabled,
.login-signup-tab-content button:disabled{
  color: rgba(0,0,0,0.4);
  background: rgba(128, 128, 128, .3);
  border-color: rgba(128, 128, 128, .1);
}

.login-signup-tab-content .button:disabled svg g,
.login-signup-tab-content button:disabled svg g{
  fill:rgba(128, 128, 128, .5) !important;
}

.login-signup-tab-content .button:disabled:hover,
.login-signup-tab-content button:disabled:hover{
  color: rgba(0,0,0,0.4);
  background: rgba(128, 128, 128, .3);
  border-color: rgba(128, 128, 128, .1);
}

.login-signup-tab-content .button.login-facebook{
  font-size:16px;
  color:#ffffff;
  text-transform:uppercase;
  text-align:center;
  padding:15px 30px;
  margin:0;
  width:100%;
  background: #3b579d url(/static/media/facebook-white.5e55384f.svg) 15px center no-repeat;
  background-size:18px;
  border-radius:0;
}

.login-signup-tab-content .button.login-google{
  font-size:16px;
  color:#ffffff;
  text-transform:uppercase;
  text-align:center;
  padding:15px 30px;
  margin:0;
  width:100%;
  background: rgba(0, 0, 0, .7) url(/static/media/google.695a3160.svg) 15px center no-repeat;
  background-size:18px;
  border-radius:0;
}

@media screen and (max-height: 420px) {
  .login-signup-wrapper{
    align-items: flex-start;
    height:inherit;
  }
}

.signup-wrapper{
  text-align:center;
  max-width:800px;
  margin:0 auto;
  padding:50px;
}

.signup-wrapper img{
  width:100%;
  max-width:350px;
  margin-bottom:30px;
}

.signup-wrapper h2{
  margin-bottom:15px;
}

.signup-wrapper p{
  font-size:15px;
  line-height:26px;
  color:#768191;
  width:100%;
  max-width:600px;
  margin: 0 auto 15px;
}

.signup-wrapper p strong{
  font-weight:700;
}

.signup-wrapper .signup-step{
  display:none;
  margin-bottom:50px;
}

.signup-wrapper .signup-step.signup-step-visible{
  display:block;
}

.signup-step .signup-step-wrapper{
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
  flex-wrap:wrap;
  padding:30px 0;
  width:100%;
  max-width:768px;
  margin:0 auto;
}

.signup-step #signup-step3 .signup-step-wrapper{
  padding-bottom:0;
}

.signup-step .signup-step-wrapper p{
  font-size:14px;
  margin-bottom:25px;
}

.signup-step .signup-step-wrapper p + p{
  margin-bottom:0;
}

.signup-step .signup-step-wrapper p span{
  font-size:15px;
  text-transform:uppercase;
  font-weight:700;
}

.signup-step .signup-step-wrapper .success-icon{
  width:100%;
  margin-bottom:25px;
}

.signup-step .signup-step-wrapper .success-icon img{
  width:152px;
  margin:0 auto;
}

.signup-step .signup-step-wrapper ul{
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
  flex-wrap:wrap;
  width:100%;
}

.signup-step .signup-step-wrapper ul li{
  width:calc(50% - 50px);
  margin-bottom:25px;
}

.signup-step .signup-step-wrapper label{
  display:block;
  font-size:14px;
  text-align:left;
  color:#768191;
  margin-bottom:5px;
}

.signup-step .signup-step-wrapper input[type="text"],
.signup-step .signup-step-wrapper input[type="email"],
.signup-step .signup-step-wrapper input[type="password"],
.signup-step .signup-step-wrapper select{
  width:100%;
}

.signup-step .btn,
.signup-step button,
.signup-step input[type="submit"]{
  font-size:15px;
  display:inline-block;
  padding:15px 30px;
  height:auto;
  color:#ffffff;
  border: 1px solid #f15825;
  background: #f15825;
  border-radius:6px;
}

.signup-step .btn:hover,
.signup-step button:hover,
.signup-step input[type="submit"]:hover{
  background-color: #272727;
  border-color: #272727;
  box-shadow: 0 8px 26px 0 rgba(0, 0, 0, .2);
}

.signup-step .btn:disabled,
.signup-step button:disabled,
.signup-step input[type="submit"]:disabled{
  color: rgba(0,0,0,0.4);
  background: rgba(128, 128, 128, .3);
  border-color: rgba(128, 128, 128, .1);
}

.signup-step .btn:disabled:hover,
.signup-step button:disabled:hover,
.signup-step input[type="submit"]:disabled:hover{
  color: rgba(0,0,0,0.4);
  background: rgba(128, 128, 128, .3);
  border-color: rgba(128, 128, 128, .1);
}

.signup-step .signup-step-wrapper-left{
  width:50%;
  padding-right:75px;
  border-right:1px solid rgba(0,0,0,.2);
}

.signup-step .signup-step-wrapper-left p{
  font-size:14px;
}

.signup-step .signup-step-wrapper-left p span{
  font-size:16px;
  text-transform:uppercase;
}

.signup-step .signup-step-wrapper-left ul li{
  width:100%;
  margin-bottom:25px;
}

.signup-step .signup-step-wrapper-right{
  width:50%;
  padding-left:75px;
}

.signup-step .signup-step-wrapper-right p{
  font-size:14px;
}

.signup-step .signup-step-wrapper-right p span{
  font-size:16px;
  text-transform:uppercase;
}

.signup-step .signup-step-wrapper-right .facebook{
  display:inline-block;
  font-family: "Fira Sans",sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 32px;
  color: #ffffff;
  text-transform:uppercase;
  text-decoration:none;
  padding:10px 15px;
  width:100%;
  background:#3b579d url(/static/media/facebook-white.5e55384f.svg) 15px center no-repeat;
  background-size:20px auto;
  border:1px solid #3b579d;
  border-radius:6px;
}

.signup-step .signup-step-wrapper-right .facebook:hover{
  background:rgba(0, 0, 0, .9) url(/static/media/facebook-white.5e55384f.svg) 15px center no-repeat;
  background-size:20px auto;
}

.signup-step .signup-step-wrapper-right .google{
  display:inline-block;
  font-family: "Fira Sans",sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 32px;
  color: #ffffff;
  text-transform:uppercase;
  text-decoration:none;
  padding:10px 15px;
  width:100%;
  background:rgba(0, 0, 0, .7) url(/static/media/google.695a3160.svg) 15px center no-repeat;
  background-size:20px auto;
  border:1px solid rgba(0, 0, 0, 0);
  border-radius:6px;
}

.signup-step .signup-step-wrapper-right .google:hover{
  background:rgba(0, 0, 0, .9) url(/static/media/google.695a3160.svg) 15px center no-repeat;
  background-size:20px auto;
}

.signup-step .signup-step-wrapper-right ul li{
  width:100%;
  margin-bottom:25px;
}

main{
  display:block;
}

.main-wrapper{
  background: #3d3d3d;
}

.white-wrapper{
  background:#f3f5f8;
}

.admin-sidebar{
  padding-left:250px;
}

aside{
  position: fixed;
  left:0;
  top:0;
  width:250px;
  height:100vh;
  z-index:1;
  transition:all .5s ease;
  -webkit-transition:all .5s ease;
  -moz-transition:all .5s ease;
}

.aside-slide{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 70px;
  z-index: 1;
}

.aside-slide-wrapper{
  padding:20px 10px;
  display: flex;
  height: 100%;
  flex-direction: column;
  background: #141414;
}

.aside-slide-wrapper .logo{
  width:75%;
}

.aside-menu{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 70px;
  width: 200px;
}

.aside-menu-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.aside-menu-wrapper nav{
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.aside-menu-wrapper nav ul{
  padding: 20px 0;
}

.aside-menu-wrapper nav ul li a{
  font-size:13px;
  font-weight: 500;
  color:#768191;
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 20px 12px 30px;
}

.aside-menu-wrapper nav ul li.active a,
.aside-menu-wrapper nav ul li a:hover{
  color:#f15825;
}

.aside-menu-wrapper nav ul li a span{
  display: inline-block;
  font-size: 16px;
  width: 20px;
  text-align: center;
  margin-right: 20px;
  border-radius: 2px;
  opacity: .8;
  vertical-align: top;
}

.aside-menu-wrapper nav ul li.active a span,
.aside-menu-wrapper nav ul li a:hover span{
  color:#f15825;
}

.admin-content{
  position:relative;
  left:0;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
}

.admin-content-wrapper{
  display: inline-block;
  width:100%;
  min-height:100vh;
}

.admin-content-wrapper-inner{
  padding-left:30px;
}

.admin-content-header{
  padding:10px 30px;
  margin-bottom:-30px;
}

.admin-content-header-wrapper{
  display:flex;
  justify-content:space-between;
  align-items:center;
}

.admin-content-header-left{
  font-size:13px;
  font-weight: 500;
}

.admin-title{
  display:inline-block;
  vertical-align:middle;
}

.admin-title-wrapper{
  font-size:15px;
  text-transform:uppercase;
  color: #1e1f22;
  display:flex;
  justify-content:flex-start;
  align-items:flex-end;
}

.admin-title img{
  display:inline-block;
  max-width:inherit;
  height:48px;
  margin-right:-5px;
  margin-top:-5px;
  z-index:-1;
}

.admin-mobile-menu{
  display:none;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  vertical-align:middle;
  padding: 0;
  margin:0 15px 0 -30px;
}

.menu:hover {
  background:transparent;
  box-shadow:none;
}

.menu svg{
  width:38px;
  height:38px;
}

.line {
  fill: none;
  stroke: #768191;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 3;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -40;
  stroke-width: 3;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
}

.admin-content-header-right .loggedin-user-photos{
  display: flex;
}

.admin-content-header-right .loggedin-user-photos-item{
  display: inline-block;
  width: 38px;
  height: 38px;
  border: 3px solid #f3f5f8;
  border-radius: 50%;
  overflow:hidden;
  cursor: pointer;
}

.admin-content-header-right .loggedin-user-photos-item img{
  border-radius:38px;
}

.profile-link {
  display: block;
  width: 100%;
  padding: 16px 20px 16px 65px;
  position: relative;
  border-bottom: 1px solid #eceff5;
}

.profile-link:hover {
  color:#ffffff;
  background-color: #141414;
  border-bottom-color: transparent;
}

.profile-link-photo {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -15px;
  border-radius: 50%;
  overflow:hidden;
}

.profile-link-photo img{
  border-radius: 50%;
}

.profile-link-name {
  display: block;
  color: #1d2329;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-link-description {
  display: block;
  color: #7f8fa4;
  font-size: 11px;
  line-height: 1;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-link:hover .profile-link-name,
.profile-link:hover .profile-link-description{
  color:#ffffff;
}

.admin-content-header-right .loggedin-user-photos.hover > .dropdown{
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
}

.admin-content-body{
  display:block;
  background:#ffffff;
  margin:30px 0;
  padding:30px;
  border-radius:12px 0 0 12px;
  box-shadow:0 0 30px 0 #e4e7eb;
}

.admin-element{
  padding:30px;
  background: #f8fafc;
  border-radius: 6px;
}

.admin-element-mb30{
  margin-bottom:30px;
}

.list-header{
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-bottom:20px;
}

.details-header{
  display:flex;
  justify-content:space-between;
  align-items:center;
}

.list-action,
.details-action{
  display:flex;
  justify-content:flex-end;
  align-items:center;
}

.list-views{
  margin-right:10px;
}

.list-search{
  position:relative;
  margin-right:10px;
}

.list-search input[type="text"]{
  font-size: 12px;
  padding-top:9px;
  padding-bottom:8px;
}

.list-filter{
  margin-right:10px;
}

.list-add-remove .button{
  margin:0;
}

.listing-view .row{
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:0 30px;
  margin:0 -30px;
}

.listing-view .row:last-child{
  margin-bottom:-30px;
}

.listing-view .row:nth-child(2n){
  background: #ffffff;
}

.listing-view .row:not(.row-head):hover{
  background: #f2f5f9;
}

.listing-view .row:not(.row-head).selected{
  color:#ffffff;
  background:#f15825;
}

.details-view .cols-w-5,
.listing-view .cols-w-5{
  width:5%;
}

.details-view .cols-w-10,
.listing-view .cols-w-10{
  width:10%;
}

.details-view .cols-w-15,
.listing-view .cols-w-15{
  width:15%;
}

.details-view .cols-w-20,
.listing-view .cols-w-20{
  width:20%;
}

.details-view .cols-w-25,
.listing-view .cols-w-25{
  width:25%;
}

.details-view .cols-w-30,
.listing-view .cols-w-30{
  width:30%;
}

.details-view .cols-w-35,
.listing-view .cols-w-35{
  width:35%;
}

.details-view .cols-w-40,
.listing-view .cols-w-40{
  width:40%;
}

.details-view .cols-w-45,
.listing-view .cols-w-45{
  width:45%;
}

.details-view .cols-w-50,
.listing-view .cols-w-50{
  width:50%;
}

.details-view .cols-w-55,
.listing-view .cols-w-55{
  width:55%;
}

.details-view .cols-w-60,
.listing-view .cols-w-60{
  width:60%;
}

.details-view .cols-w-65,
.listing-view .cols-w-65{
  width:5%;
}

.details-view .cols-w-70,
.listing-view .cols-w-70{
  width:70%;
}

.details-view .cols-w-75,
.listing-view .cols-w-75{
  width:75%;
}

.details-view .cols-w-80,
.listing-view .cols-w-80{
  width:80%;
}

.details-view .cols-w-85,
.listing-view .cols-w-85{
  width:85%;
}

.details-view .cols-w-90,
.listing-view .cols-w-90{
  width:90%;
}

.details-view .cols-w-95,
.listing-view .cols-w-95{
  width:95%;
}

.w-100,
.details-view .cols-w-100,
.listing-view .cols-w-100{
  width:100%;
}

.listing-view .cols.cols-align-center,
.listing-view .row-head .cols.cols-align-center{
  text-align:center;
}

.listing-view .cols.cols-align-right,
.listing-view .row-head .cols.cols-align-right{
  text-align:right;
}

.listing-view .cols:first-child{
  padding-left:0;
}

.listing-view .cols:last-child{
  padding-right:0;
}

.listing-view .row-head .cols{
  font-size: 11px;
  font-weight: 500;
  color: #9fa9ba;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
}

.listing-view .row-head .cols span.icon-filter-down,
.listing-view .row-head .cols span.icon-filter-up{
  cursor:pointer;
}

.listing-view .cols{
  padding:20px;
  text-transform: none;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listing-view .cols label.card-label{
  font-size:12px;
  text-transform:uppercase;
  margin-bottom:5px;
  opacity:.7;
  display:none;
}

.listing-view .cols img{
  max-width:100%;
  border-radius:6px;
  margin:0;
}

.listing-view .cols.listing-view-thumb{
  overflow: visible;
  white-space: inherit;
  text-overflow: inherit;
}

.listing-view .cols .rounded-thumb{
  display:flex;
  justify-content:center;
  align-items:center;
  width:72px;
  height:72px;
  border-radius: 72px;
  overflow:hidden;
}

.listing-view .cols img.rounded{
  border-radius:72px;
}

.listing-view .cols .checkbox{
  display: inline-block;
} 

.listing-view .cols:last-child .iconic-button{
  margin-right:0;
  background:transparent;
}

.listing-view .cols:last-child .iconic-button span{
  color:#768191;
}

.listing-view .selected .cols:last-child .iconic-button span{
  color:#ffffff;
}

.listing-view .cols:last-child .iconic-button:hover{
  background:transparent;
  box-shadow:none;
}

.listing-view .cols:last-child .iconic-button:hover span{
  color:#141414;
}

.card-view{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap:wrap;
}

.card-view .row.row-head{
  display:none;
}

.card-view .row:not(.row-head){
  display:inline-block;
  width:calc(20% - 20px);
  padding:20px;
  margin:0 0 20px 0;
  background:#ffffff;
  border-radius:6px;
  -webkit-border-radius:6px;
  -moz-border-radius:6px;
  transition:transform .2s,box-shadow .2s;
}

.card-view .row:not(.row-head):hover{
  background:#ffffff;
  box-shadow: 0 2px 15px 0 rgba(202,207,214,.75);
  transform: translate3d(0,-4px,0);
}

.card-view .row:not(.row-head).selected{
  color: #ffffff;
  background: #f15825;
}

.card-view .cols{
  width:100%;
}

.card-view .cols.cols-align-center{
  text-align:left;
}

.card-view .cols,
.card-view .cols:first-child{
  padding:0 0 7px 0;
}

.card-view .cols:last-child{
  padding:0;
}

.card-view .cols img{
  width:100%;
  max-width:100%;
  margin-bottom:15px;
}

.card-view .cols label.card-label{
  text-align:left;
  display:inline-block;
  width:80px;
}

.pagination{
  font-size: 13px;
  color: #616a75;
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-top:30px;
  width:100%;
}

.pagination-control{
  font-size: 10px;
  color: #616a75;
  text-decoration: none;
  line-height: 28px;
  text-align: center;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 0 4px;
  background: #ffffff;
  border:1px solid #e9ecf0;
  border-radius: 4px;
  cursor:pointer;
}

.pagination-pages{
  display: inline-block;
  padding: 0 15px;
}

.pagination-pages .pagination-item{
  font-size: 13px;
  color: #616a75;
  text-decoration: none;
  line-height: 28px;
  text-align: center;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 0 6px;
  background: #f5f7fa;
  border:1px solid #f5f7fa;
  border-radius: 4px;
  cursor:pointer;
}

.pagination-pages .pagination-item.active{
  background:#ffffff;
  border:1px solid #e9ecf0;
}

.pagination-control:hover,
.pagination-pages .pagination-item:hover{
  color:#ffffff;
  background:#f15825;
  border:1px solid #f15825;
}

.details-view .row,
.tab-content .row{
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
  margin-bottom:30px;
}

.details-view .row:last-child,
.details-view .row .row .row,
.tab-content .row:last-child,
.tab-content .row .row .row{
  margin-bottom:0;
}

.details-view .cols label,
.tab-content .cols label{
  font-size:13px;
  text-transform:uppercase;
  opacity:.7;
  display:inline-block;
  width:100%;
  margin-bottom:10px;
}

.details-view .cols .rounded-thumb,
.tab-content .cols .rounded-thumb{
  display:flex;
  justify-content:center;
  align-items:center;
  max-width:250px;
  max-height:250px;
  border-radius:250px;
  overflow:hidden;
}

.details-view .cols img,
.tab-content .cols img{
  width:calc(100% - 30px);
  max-width: 250px;
  border-radius: 6px;
  margin:0 0 15px;
}

.details-view .cols img.rounded,
.tab-content .cols img.rounded{
  border-radius:250px;
}

.details-view .cols p,
.tab-content .cols p{
  margin-bottom:15px;
}

.details-view input[type="text"], 
.details-view input[type="email"], 
.details-view input[type="password"], 
.details-view select{
  width:calc(100% - 30px);
  max-width:450px;
}

.tabs-view .tabs{
  border-radius: 6px;
  margin-bottom:30px;
  position:relative;
}

.tabs-view .tabs .tab-item{
  font-family: "Fira Sans",sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #768191;
  text-decoration: none;
  display: inline-block;
  padding:15px 20px;
  border-radius: 6px;
  cursor:pointer;
  position:relative;
  z-index:1;
}

.tabs-view .tabs .tab-item span{
  font-size: 15px;
  display: inline-block;
  margin-top:-2px;
  margin-right:8px;
}

.tabs-view .tabs .tab-item:hover{
  color:#f15825;
}

.tabs-view .tabs .tab-item.tab-item-active{
  color:#f15825;
  background:#ffffff;
}

.tabs-view .tabs .tab-item-slider{
  position:absolute;
  left:0;
  top:0;
  width:78px;
  height:100%;
  background:#ffffff;
  border-radius: 6px;
  z-index:0;
  transition: 0.3s ease all;
}

.tabs-view .tabs .tab-item:nth-child(1).tab-item-active ~ .tab-item-slider{
  left: 0;
  width:100px;
}

.tabs-view .tabs .tab-item:nth-child(2).tab-item-active ~ .tab-item-slider{
  left: 100px;
  width:104px;
}

.tabs-view .tabs .tab-item:nth-child(3).tab-item-active ~ .tab-item-slider{
  left: 204px;
  width:96px;
}

.tabs-view .tabs .tab-item:nth-child(4).tab-item-active ~ .tab-item-slider{
  left: 300px;
  width:172px;
}

.tabs-view .tabs .tab-item:nth-child(1):hover ~ .tab-item-slider {
  left: 0;
  width:100px;
}

.tabs-view .tabs .tab-item:nth-child(2):hover ~ .tab-item-slider {
  left: 100px;
  width:104px;
}

.tabs-view .tabs .tab-item:nth-child(3):hover ~ .tab-item-slider {
  left: 204px;
  width:96px;
}

.tabs-view .tabs .tab-item:nth-child(4):hover ~ .tab-item-slider {
  left: 300px;
  width:172px;
}

.tab-content{
  display:none;
}

.tab-content.tab-content-active{
  display:block;
}

.tab-content p{
  margin-bottom:15px;
}

.tab-content p:last-child{
  margin-bottom:0;
}

.tab-content .title{
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
  flex-wrap:wrap;
  padding-bottom:30px;
}

.tab-content .title h3{
  width:100%;
}

.tab-content .title p{
  margin:0;
}

.tab-content .listing-view .row{
  align-items: center;
  padding:0 20px;
  margin:0;
}

.tab-content .listing-view .row .cols img{
  max-width:100%;
  margin:0;
}

.tab-content .listing-view .row .cols p{
  font-size:12px;
  opacity:.7;
}

.popups {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 6;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: auto
}

.popups.visible {
	display: block
}

.popups-disable-events {
	pointer-events: none
}

.popup-background {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(3, 6, 16, .75);
	will-change: opacity
}

.popup-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: auto;
	padding: 30px;
	text-align: center;
	white-space: nowrap
}

.popup-container:after {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle
}

.popup {
	position: relative;
  display: inline-block;
  padding:30px;
  width: 100vw;
  max-width: 860px;
	background: #fff;
	border-radius: 6px;
	text-align: left;
	vertical-align: middle;
	white-space: normal;
	will-change: opacity, transform
}

.popup-shadow {
	box-shadow: 0 10px 60px 0 rgba(0, 0, 0, .15)
}

.popup-medium{
  max-width:600px;
}

.popup-wide {
	width: 100%;
	max-width: 1240px
}

.popup-title {
  font-size: 28px;
  font-weight: 500;
  color: #1d2329;
  padding:0 30px 20px;
  margin:0 -30px 20px;
  border-bottom:1px solid #ebeff5;
}

.popup-footer {
  padding:20px 30px 0;
  margin:30px -30px 0;
  border-top:1px solid #ebeff5;
}

.privacy-policy{
  max-width: 1080px;
  margin: 0 auto;
  padding: 50px;
}

.privacy-policy img {
  width: 100%;
  max-width: 350px;
  margin-bottom: 30px;
}

.privacy-policy h2 {
  margin-bottom: 30px;
}

.privacy-policy h3{
  margin-bottom:15px;
}

.privacy-policy p{
  margin-bottom:15px;
}

.privacy-policy span{
  color:#1e1f22;  
}

.privacy-policy ul{
  margin-left:15px;
}

.privacy-policy ul li{
  line-height:22px;
  list-style-type: disc;
  margin-bottom:15px;
}

.privacy-policy a{
  color:#f15825;
}

.privacy-policy a:hover{
  color:#1e1f22;  
}

.email-footer,
.instagram-footer,
.twitter-footer,
.facebook-footer{
  display:inline-block;
  width:32px;
  height:32px;
  border-radius:32px;
  margin:0 5px;
  transition:all .5s ease;
}

.email-footer{
  background: url(/static/media/email-footer.2da6a686.svg) center no-repeat;
  background-size:cover;
}

.instagram-footer{
  background: url(/static/media/instagram-footer.3d31d52f.svg) center no-repeat;
  background-size:cover;
}

.twitter-footer{
  background: url(/static/media/twitter-footer.2ddca4c8.svg) center no-repeat;
  background-size:cover;
}

.facebook-footer{
  background: url(/static/media/facebook-footer.d8d7cc10.svg) center no-repeat;
  background-size:cover;
}

.email-footer:hover,
.instagram-footer:hover,
.twitter-footer:hover,
.facebook-footer:hover{
  transform:scale(1.1);
}

.MuiAutocomplete-popupIndicator, .MuiAutocomplete-clearIndicator{
  display: none !important;
}

.MuiInputBase-root{
  border: none !important;
  padding: 0 !important;
}

.MuiOutlinedInput-notchedOutline{
  border: none !important;
}

.input-wrapper {
  position: relative;
  display: flex;
}

.show-password {
  width:38px;
  height:38px;
  position: absolute;
  top: 0;
  right:0;
  background:url(/static/media/view.7f987174.svg) center no-repeat;
  background-size:18px;
  text-indent:-999999px;
}

.hide-password {
  background:url(/static/media/hide.f62e41ea.svg) center no-repeat;
  background-size:18px;
}

.show-password:hover {
  cursor: pointer;
}
@media screen and (max-width:1580px) { 
  .card-view .row:not(.row-head){
    width: calc(25% - 15px);
  }
}

@media screen and (max-width:1280px) { 
  .card-view .row:not(.row-head){
    width: calc(33.33% - 15px);
  }
}

@media screen and (max-width:1180px) { 
  h2{
    width:100%;
    margin-bottom:20px;
  }
  .list-header h2{
    width:auto;
    max-width:40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom:0;
  }
}

@media screen and (max-width:1080px) { 
  .admin-sidebar{
    padding-left:0;
  }
  aside{
    left:-250px;
    background-color: #f3f5f8;
  }
  .admin-mobile-menu{
    display:inline-block;
    vertical-align:middle;
  }
  .sidebar-open{
    overflow-x: hidden;
  }
  .sidebar-open aside{
    left:0;
  }
  .sidebar-open .admin-content{
    left:250px;
  }
}

@media screen and (max-width: 880px) {
  .signup-wrapper{
    padding-left:30px;
    padding-right:30px;
  }
  .privacy-policy{
    padding-left:30px;
    padding-right:30px;
  }
}

@media screen and (max-width: 800px) {
  .iconic-button{
    padding:10px;
    width:28px;
  }

  .admin-title img{
    height:38px;
  }
  .signup-step .signup-step-wrapper-left{
      padding-right:30px;
  }
  .signup-step .signup-step-wrapper-right{
      padding-left: 30px;
  }
  .signup-step .signup-step-wrapper ul li{
    width: calc(50% - 15px);
  }
  .signup-step .signup-step-wrapper-left ul li,
  .signup-step .signup-step-wrapper-right ul li{
    width:100%;
  }

  .admin-content-body{
    padding:20px;
  }
  .admin-element{
    padding:20px;
  }

  .list-header{
    flex-wrap:wrap;
    margin-bottom: 10px;
  }
  .list-header h2,
  .list-action{
    justify-content:flex-start;
    width:100%;
  }
  .list-header h2{
    margin-bottom:20px;
  }
  .list-views{
    display:none;
  }
  .list-search{
    width:100%;
  }
  .list-search input[type="text"]{
    width:100%;
  }
  .listing-view .row{
    padding: 0 20px;
    margin: 0 -20px;
  }
  .listing-view .cols .rounded-thumb{
    width: 58px;
    height: 58px;
    border-radius: 58px;
  }
  .listing-view .cols img{
    max-width:100%;
  }
  .listing-view .cols img.rounded{
    border-radius: 58px;
  }

  .details-header h2{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom:0;
    width:calc(100% - 180px);
  }
  .details-header .details-action{
    width:180px;
  }
  .details-view > .row,
  .details-view .tab-content > .row{
    flex-wrap:wrap;
  }
  .details-view > .row > .cols,
  .details-view .tab-content > .row > .cols{
    width:100%;
    margin-bottom:20px;
  }
  .details-view > .row > .cols:last-child,
  .details-view .tab-content > .row > .cols:last-child{
    margin-bottom:0;
  }

  .card-view{
    justify-content:space-between;
  }
  .card-view .row:not(.row-head){
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin: 0 0 20px 0;
  }
}

@media screen and (max-width:767px) { 
  .menu {
    margin-left:-5px;
    margin-right:5px;
  }
  .signup-step .signup-step-wrapper{
    padding:0;
  }
  .signup-step .signup-step-wrapper-left,
  .signup-step .signup-step-wrapper-right{
      width:100%;
      padding-left:0;
      padding-right:0;
      border-right:0;
  }
  .signup-step .signup-step-wrapper-right .facebook,
  .signup-step .signup-step-wrapper-right .google{
      margin:0;
  }
  .signup-step .signup-step-wrapper p,
  .signup-step .signup-step-wrapper-left ul li,
  .signup-step .signup-step-wrapper-right ul li,
  .signup-step .signup-step-wrapper ul li{
      margin-bottom:15px;
  }
  .signup-step .signup-step-wrapper ul li{
    width:100%;
  }
  .signup-step .btn{
      width:100%;
  }
  .signup-step .signup-step-wrapper .success-icon img{
    width:98px;
  }
  .admin-content-wrapper-inner{
    padding-left:0;
  }
  .admin-content-header{
    padding:10px 15px;
  }
  .admin-content-body{
    padding:15px;
  }
  .admin-element{
    padding:15px;
  }
  .privacy-policy{
    padding-left: 15px;
    padding-right: 15px;
  }
  .privacy-policy img{
    padding:0 15px;
  }
}

@media screen and (max-width: 613px) {
  .cols-w-100-mobile{
    width:100% !important;
    margin:0 0 20px;
  }

  .signup-wrapper ul li{
    width:100%;
  }

  .list-action, .details-action{
    flex-wrap:wrap;
  }
  .list-action{
    justify-content: space-between;
  }
  .list-views,
  .list-search,
  .list-filter,
  .list-add-remove{
    width:calc(50% - 10px);
    margin:0 0 10px 0;
  }
  .list-search{
    width:100%;
  }
  .list-filter .button,
  .list-search input[type="text"]{
    width:100%;
    margin:0;
  }

  .listing-view{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .listing-view .row.row-head{
    display:none;
  }
  .listing-view .row:not(.row-head){
    display: inline-block;
    padding: 20px;
    width: 100%;
    max-width: 100%;
    margin: 0 0 15px 0;
    background: #ffffff;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    transition:transform .2s,box-shadow .2s;
  }
  .listing-view .row:not(.row-head):last-child{
    margin:0;
  }
  .listing-view .row:not(.row-head) .cols{
    width:100%;
    padding: 0 0 7px 0;
  }
  .listing-view .row:not(.row-head) .cols.cols-align-center{
    text-align:left;
  }
  .listing-view .cols .rounded-thumb{
    margin-bottom: 15px;
  }
  .listing-view .row:not(.row-head) .cols label.card-label{
    text-align: left;
    display: inline-block;
    width: 80px;
  }

  .details-view .row, .tab-content .row{
    flex-wrap:wrap;
    margin-bottom:0;
  }
  .details-view input[type="text"], .details-view input[type="email"], .details-view input[type="password"], .details-view select{
    width:100%;
    max-width:100%;
  }

  .card-view .row:not(.row-head){
    width:100%;
    max-width:100%;
    margin:0 0 15px 0;
  }
}

@media screen and (max-width:568px) { 
  header .logo{
    max-width:100%;
    height:auto;
  }
}
.app {
  text-align: center;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    -webkit-animation: app-logo-spin infinite 20s linear;
            animation: app-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

@-webkit-keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

